import 'jquery.cookie'
$(function() {
	// 国名が変更されたら発動
	$('select[id=header_country_id]').change(function() {
		// 選択されている国名を取得
    var country_id = $("[id=header_country_id] option:selected").val()
    if(!country_id){
      $.removeCookie("country_id", {path: "/"})
      $.removeCookie("port_id", {path: "/"})
    }else{
      $.cookie("country_id", country_id, {path: "/"})
      $.removeCookie("port_id", {path: "/"})
      $('[id=header_port_id] option').remove()
      $.ajax({
        type: "GET",
        url:  "/api/port/" + country_id,
        data: {country_id: country_id}
      }).done(function(data){
        // 成功の場合の処理
        $('[id=header_port_id]').append($('<option>').html('Select Port').val(''))
        $.each(data, function(index, value) {
          $('[id=header_port_id]').append($('<option>').html(value[0]).val(value[1]))
        })
      }).fail(function(){
        // エラーの場合処理
        console.log('error')
      })
    }
	})

  $('select[id=header_port_id]').change(function() {
		// 選択されている国名を取得
    var port_id = $("[id=header_port_id] option:selected").val()
    $.removeCookie("port_id", {path: "/"})
    if(port_id){
      $.cookie("port_id", port_id, {path: "/"})
    }
    if($("select[id=port_id] option:selected").val() || $("select[id=order_form_port_id] option:selected").val()){
      location.reload()
    }
	})

  // Languageを元にURLを変更
  function change_language_url (language_value) {
    var path_name = location.pathname
    var origin = location.origin
    var search = location.search
    var string = ""
    if(path_name == "/en" || path_name.indexOf( '/en/' ) > -1){
      var path_array = path_name.split("/")
      var index = $.inArray('en', path_array)
      path_array[index] = language_value
      string = path_array.join('/')
    }
    else if(path_name == "/fr" || path_name.indexOf( '/fr/' ) > -1){
      var path_array = path_name.split("/")
      var index = $.inArray('fr', path_array)
      path_array[index] = language_value
      string = path_array.join('/')
    }
    else if(path_name == "/sw" || path_name.indexOf( '/sw/' ) > -1){
      var path_array = path_name.split("/")
      var index = $.inArray('sw', path_array)
      path_array[index] = language_value
      string = path_array.join('/')
    }
    // トップページ
    else if(path_name == '/') {
      var path_array = [language_value]
      string = '/' + path_array.join('/')
    }
    // languageが入っていないURLの場合
    else {
      string = '/' + language_value + path_name
    }

    var url = origin + string + search
    location.href = url
  }

  // Language変更ボタン切り替え
  var selectedItem = $("ul.select_box li.selected").html()
  var selectBox = $("ul.select_box")
  var selectItems = $("ul.select_box li")
  var display_selectedItem = $(".display_selectedItem")
  var display = $(".display_selectedItem span")

  display.html(selectedItem)

  display_selectedItem.on("click",function(){
    selectBox.toggle()
  })

  selectItems.on("click",function(){
    selectItems.removeClass("selected")
    $(this).addClass("selected")
    var class_name = $(this)[0].id
    selectedItem = $("li.selected").html()
    selectBox.toggle()
    display.html(selectedItem)
    display.removeClass()
    display.addClass(class_name)
    change_language_url(class_name)
  })
})

